import { useRouter } from 'next/navigation';
import { useRef, useState, useEffect, useCallback } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { Search32 } from '@packages/themes/icons';
import {
  Box,
  CloseIconButton,
  IconButton,
  TextField,
  useDebounce,
  useI18n,
  useSnackbar,
} from '@packages/shared';
import { useSession } from '@packages/utilities';
import { usePageTransition } from '@packages/shared/src/providers/PageTransitionProvider/usePageTransition';

import { useSearchQuery } from '../../useSearchQuery';
import { useSearchFieldTracking } from './useSearchFieldTracking';
import { DynamicSearchAnimatedPlaceholder } from './SearchAnimatedPlaceholder';
import { useInspiringSearchSettings } from '../../useInspiringSearchSettings';
import { onKey, onKeys } from './utils';
import { SearchFieldPopup } from './SearchField.Popup';

const messages = defineMessages({
  placeholder: { id: 'searchbar.placeholder', defaultMessage: 'Lieblingsartikel suchen...' },
  ariaLabelInput: {
    id: 'searchbar.input.aria-label',
    defaultMessage: 'suchen-eingabe',
  },
  ariaLabelResetButton: {
    id: 'searchbar.resetbutton.aria-label',
    defaultMessage: 'zurücksetzen',
  },
  ariaLabelButton: {
    id: 'searchbar.button.aria-label',
    defaultMessage: 'suchen-button',
  },
  searchBarEmptyAlert: {
    id: 'searchbar.invalid.empty',
    defaultMessage: 'Bitte geben Sie zunächst Ihren Suchbegriff ein.',
  },
});

export const INITIAL_INPUT_VALUE = '';

export interface SearchFieldProps {
  /**
   * Sets focus on mounting
   * @default false
   */
  autofocus?: boolean;
  /** If `true`, don't show suggestions or history, and don't reflect current route in input
   * @default false
   * */
  isolated?: boolean;
  /** A search was submitted from any source (user input, history, suggest, ...), and will lead to a route change */
  onNavigate?: () => void;
  /** Optionally modify the URL that will be navigated to before navigation happens, e.g. to append query params */
  postProcessSearchUrl?: (url: string) => string;
}

/** Integrated search textfield component with iconbutton used in header */
export const SearchField = ({
  autofocus,
  isolated,
  onNavigate,
  postProcessSearchUrl,
}: SearchFieldProps) => {
  const { dispatchSearchGtmEvent } = useSearchFieldTracking();
  const searchQuery = useSearchQuery();
  const { formatMessage } = useIntl();
  const router = useRouter();
  const { language, localizeUrl } = useI18n();
  // INFO: needs to get the jwt token here and provide to the popover components to avoid suspense + swr anti-pattern that the key can be null. update when swr completly implements suspense concept in further versions
  const { jwtToken } = useSession();
  const { isInspiringSearchEnabled } = useInspiringSearchSettings();
  const { addToast } = useSnackbar();

  const initialValue = searchQuery && !isolated ? searchQuery : INITIAL_INPUT_VALUE;
  const [value, setValue] = useState(initialValue);
  const debouncedValue = useDebounce(value);
  const searchTermForSuggestions =
    debouncedValue === INITIAL_INPUT_VALUE ? undefined : debouncedValue;

  const [hasPopupRelevantFocus, setHasPopupRelevantFocus] = useState(false);
  const showPopup = jwtToken && !isolated && hasPopupRelevantFocus;

  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const focusOnInput = () => {
    inputRef.current?.focus();
  };
  const focusOnWrapper = () => {
    setHasPopupRelevantFocus(false);
    wrapperRef.current?.focus();
  };

  const shiftFocusFromInputToWrapper = useCallback(() => {
    if (document.activeElement === inputRef.current) {
      focusOnWrapper();
    }
  }, []);

  const updateInput = (newValue: string) => {
    setValue(newValue);
  };

  const pageTransition = usePageTransition();

  const handleInputFocus = () => {
    setHasPopupRelevantFocus(true);
  };

  const handleWrapperBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    if (!event.currentTarget.contains(event.relatedTarget as Node)) {
      setHasPopupRelevantFocus(false);
    }
  };

  // if input is empty show alert, else go to search route
  const handleSubmit = () => {
    const trimmedValue = value.trim();

    if (!trimmedValue) {
      addToast({
        severity: 'error',
        message: formatMessage(messages.searchBarEmptyAlert),
        duration: 2000,
      });
      return;
    }

    // should work same as in the "old" shop system
    const url = `/s/${encodeURIComponent(trimmedValue.replace(/[\s]+/g, '+')).replace('%2B', '+')}/`;
    const finalUrl = postProcessSearchUrl?.(url) ?? url;
    const localizedUrl = localizeUrl(finalUrl, language);

    if (pageTransition) {
      pageTransition.setUrl(localizedUrl);
      pageTransition.start(() => {
        router.push(localizedUrl);

        dispatchSearchGtmEvent(trimmedValue);
        onNavigate?.();

        shiftFocusFromInputToWrapper();
      });
      // TODO maybe remove this branch after the whole app is migrated to the new app router
    } else {
      router.push(localizedUrl);

      dispatchSearchGtmEvent(trimmedValue);
      onNavigate?.();

      shiftFocusFromInputToWrapper();
    }
  };

  const handleAcceptSuggestion = (suggestion: string) => {
    updateInput(suggestion);
    onNavigate?.();

    // this needs to be deferred until the keydown callback is finished (if triggered by keyboard navigation), otherwise the closing popup prevents the anchor from navigating
    setTimeout(() => {
      focusOnWrapper();
    });
  };

  const handleAdoptSuggestion = (suggestion: string) => {
    updateInput(suggestion);
    focusOnInput();
  };

  const handleClearButton = () => {
    setValue(INITIAL_INPUT_VALUE);
    focusOnInput();
  };

  //  A change in `initialValue` means a change in the route
  //  In this case we want to reset the input and blur, similar to how everything is on initial render
  useEffect(() => {
    setValue(initialValue);
    shiftFocusFromInputToWrapper();
  }, [initialValue, shiftFocusFromInputToWrapper]);

  //  A change in `autofocus` should focus the input if need be
  //  Caution: This effect needs to run after the previous effect, which blurs the input
  //  Otherwise the call of `blur` overrides the call of `focus`
  useEffect(() => {
    if (autofocus) {
      focusOnInput();
    }
  }, [autofocus]);

  return (
    <Box
      tabIndex={0}
      sx={{ position: 'relative', display: 'flex', alignItems: 'center' }}
      onBlur={handleWrapperBlur}
      onKeyDown={onKeys({
        Enter: (event) => {
          if (event.target === wrapperRef.current) {
            focusOnInput();
          }
        },
        Escape: focusOnWrapper,
      })}
      ref={wrapperRef}
      onClick={(event: MouseEvent) => {
        // prevent interactions with the search from closing possible popups
        event.stopPropagation();
      }}
    >
      {isInspiringSearchEnabled && <DynamicSearchAnimatedPlaceholder show={!value} />}
      <TextField
        sx={{
          pt: 0,
          fieldset: {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            borderColor: hasPopupRelevantFocus ? 'primary.main' : 'grey.dark',
          },
        }}
        value={value}
        fullWidth
        onChange={(event) => {
          updateInput(event.target.value);
        }}
        onKeyDown={onKey('Enter', handleSubmit)}
        onFocus={handleInputFocus}
        ref={inputRef}
        inputProps={{
          tabIndex: -1,
          'aria-label': formatMessage(messages.ariaLabelInput),
          //  workaround for e2e testing, to be able to target the "main" search field over other search fields on the page, like inspiring search teaser
          //  TODO make a11y improvements to make the search fields distinguishable without this workaround
          'data-isolated': isolated,
          enterKeyHint: 'search',
        }}
        //  eslint-disable-next-line react/jsx-no-duplicate-props
        InputProps={{
          autoComplete: 'off',
          endAdornment: value && (
            <CloseIconButton
              onClick={handleClearButton}
              onKeyDown={onKey('Enter', handleClearButton)}
              sx={{ color: 'text.primary' }}
              aria-label={formatMessage(messages.ariaLabelResetButton)}
            />
          ),
        }}
        placeholder={isInspiringSearchEnabled ? undefined : formatMessage(messages.placeholder)}
      />
      <IconButton
        icon={
          <Search32 sx={{ color: hasPopupRelevantFocus ? 'primary.contrastText' : undefined }} />
        }
        size="large"
        sx={{
          flexShrink: 0,
          ml: '-1px',
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderColor: hasPopupRelevantFocus ? 'primary.main' : 'grey.dark',
          padding: 'inherit',
          '.MuiButton-startIcon > *:nth-of-type(1)': {
            fontSize: 32,
          },
        }}
        color={hasPopupRelevantFocus ? 'primary' : undefined}
        onKeyDown={onKey('Enter', handleSubmit)}
        onClick={handleSubmit}
        aria-label={formatMessage(messages.ariaLabelButton)}
      />
      {showPopup && (
        <SearchFieldPopup
          searchTerm={searchTermForSuggestions}
          jwtToken={jwtToken}
          onAccept={handleAcceptSuggestion}
          onAdopt={handleAdoptSuggestion}
        />
      )}
    </Box>
  );
};

//  required for next/dynamic
//  eslint-disable-next-line import/no-default-export
export default SearchField;
